<template>
    <!-- BEGIN: Side Menu -->
    <nav class="side-nav">
        <div class="flex justify-center">
            <button
                  @click="this.$root.keycloak.logout()"
                  class="text-xl text-white logout mt-8"
            >
                <font-awesome-icon icon="power-off"/>
            </button>
        </div>

        <div class="mb-8"></div>
        <ul>
            <li>
                <router-link
                      title="SGOverview"
                      :class="{
            'side-menu--active':
              currentActive === 'SGOverview' || currentActive === 'SGDetails' || currentActive === 'LEDetails',
          }"
                      :to="routerData.SGOverview.link"
                      class="side-menu"
                >
                    <div title="GlobalOverview" class="side-menu__icon">
                        <font-awesome-icon icon="list"/>
                    </div>
                    <div title="SGOverview" class="side-menu__title">SG Overview</div>
                </router-link>
            </li>
            <li>
                <router-link
                      title="GlobalOverview"
                      :class="{ 'side-menu--active': currentActive === 'GlobalOverview' }"
                      :to="routerData.GlobalOverview.link"
                      class="side-menu"
                >
                    <div title="GlobalOverview" class="side-menu__icon">
                        <font-awesome-icon icon="globe"/>
                    </div>
                    <div title="GlobalOverview" class="side-menu__title">
                        Global Overview
                    </div>
                </router-link>
            </li>
            <li>
                <router-link
                      title="GlobalOverview"
                      :class="{ 'side-menu--active': currentActive === 'Schedule' }"
                      :to="routerData.Schedule.link"
                      class="side-menu"
                >
                    <div title="Schedule" class="side-menu__icon">
                        <font-awesome-icon icon="calendar" />
                    </div>
                    <div title="Schedule" class="side-menu__title">
                        Running Twikey jobs
                    </div>
                </router-link>
            </li>
            <li>
                <router-link
                      title="Exporting"
                      :class="{ 'side-menu--active': currentActive === 'Exporting' }"
                      :to="routerData.Exporting.link"
                      class="side-menu"
                >
                    <div title="Exporting" class="side-menu__icon">
                        <font-awesome-icon icon="file-export"/>
                    </div>
                    <div title="Exporting" class="side-menu__title">Exporting</div>
                </router-link>
            </li>
            <li>
                <router-link
                      title="Summary"
                      :class="{ 'side-menu--active': currentActive === 'Summary' }"
                      :to="routerData.Summary.link"
                      class="side-menu"
                >
                    <div title="Summary" class="side-menu__icon">
                        <font-awesome-icon icon="list-check"/>
                    </div>
                    <div title="Summary" class="side-menu__title">Summary</div>
                </router-link>
            </li>
            <li>
			<router-link
				title="Smp"
				:class="{ 'side-menu--active': currentActive === 'Peppol' }"
				:to="routerData.Peppol.link"
				class="side-menu"
			>
				<div title="Peppol" class="side-menu__icon">
					<font-awesome-icon icon="file"/>
				</div>
				<div title="Peppol" class="side-menu__title">Peppol</div>
			</router-link>
		</li>
			<li>
				<router-link
					title="Global actions"
					:class="{ 'side-menu--active': currentActive === 'GlobalActions' }"
					:to="routerData.GlobalActions.link"
					class="side-menu"
				>
					<div title="Global actions" class="side-menu__icon">
						<font-awesome-icon icon="file"/>
					</div>
					<div title="Global actions" class="side-menu__title">Global actions</div>
				</router-link>
			</li>
            <!--
            <li>
                <router-link
                      title="Onderzoek"
                      :class="{ 'side-menu--active': currentActive == 'Onderzoek' }"
                      :to="routerData.Onderzoek.link"
                      class="side-menu"
                >
                    <div title="Onderzoek" class="side-menu__icon">
                        <font-awesome-icon icon="flask"/>
                    </div>
                    <div title="Onderzoek" class="side-menu__title">Onderzoek Melvin</div>
                </router-link>
            </li>
            -->
        </ul>
    </nav>
    <!-- END: Side Menu -->
</template>
<script>
import routerData from "../../routerData";

export default {
	name: "SidebarElement",
    data() {
        return {
            routerData: routerData,
        };
    },
    computed: {
        currentActive() {
            return this.$route.name;
        },
    },
};
</script>
<style scoped>
.side-nav {
    width: 20%;
    overflow-x: hidden;
    padding-right: 1.25rem;
    padding-bottom: 4rem;
}
</style>
