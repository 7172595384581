<template>
	<div class="mb-3" :class="{'oneLineCss' : oneLineCss}">
		<p class="font-bold form-label" :class="{'display-none' : noLabel }">{{ title }}</p>
		<span v-if="isCopied" class="ml-2 font-bold text-theme-1">Copied</span>
		<div :class="{ 'border border-primary-3': hasError }" class="flex flex-row input-group w-full">
			<input
				:type="type"
				:maxlength="maxLength"
				:disabled="disabled"
				:step="numberIntervals"
				class="form-control w-full"
				:placeholder="placeholder"
				:value="modelValue"
				@input="update($event.target.value)"
			/>
			<div @click="click" v-if="copySuffix" class="font-bold cursor-pointer input-group-text">
				<font-awesome-icon icon="copy"/>
			</div>
			<div v-if="showDownloadButton" class="font-bold cursor-pointer input-group-text" style="padding: 7px 9px 2px 9px">
				<span class="material-icons" style="font-size: 20px">file_download</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "inputField",
	emits: ["update:modelValue", "copy"],
	props: {
		id: String,
		title: String,
		type: String,
		disabled: Boolean,
		placeholder: String,
		numberIntervals: Number,
		//v-model in edit | :modelValue in view
		modelValue: [String, Number],
		hasError: Boolean,
		maxLength: String,
		copySuffix: Boolean,
		isCopied: Boolean,
		oneLineCss: {
			type: Boolean,
			default: false
		},
		showDownloadButton: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	computed: {
		noLabel() {
			return this.title === ''
		}
	},
	methods: {
		update(value) {
			this.$emit("update:modelValue", value);
		},
		click(){
			this.$emit("copy")
		}
	}
}
</script>

<style scoped>
.oneLineCss {
	display: flex;
}

.oneLineCss label {
	margin-right: 5px;
}

.oneLineCss > div, .oneLineCss > label {
	align-items: center
}

.display-none {
	display: none;
}
</style>