<template>
    <div id='paymentSchedule'>
        <!-- BEGIN: HTML Table Data -->
        <div class="intro-y box p-5 mt-5 ">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">

                <form
                      @submit.prevent="onFilter"
                      id="tabulator-html-filter-form"
                      class="xl:flex sm:mr-auto"
                >
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <input
                              id="tabulator-html-filter-value"
                              v-model="filter.value"
                              type="text"
                              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                              placeholder="Search..."
                              @keyup="onFilter"
                        />
                    </div>
                    <div class="mt-2 xl:mt-0">
                        <button
                              id="tabulator-html-filter-reset"
                              type="button"
                              class="btn w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                              @click="onResetFilter"
                        >
                            Reset
                        </button>
                    </div>
                </form>
                <div class="flex mt-5 sm:mt-0"></div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator"
                ></div>
            </div>
        </div>
        <!-- END: HTML Table Data -->
    </div>
</template>


<script>
import {defineComponent, ref, reactive, onMounted, onBeforeMount, watch} from "vue";
import feather from "feather-icons";
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import cash from "cash-dom";
import {useRouter, useRoute} from "vue-router";
import {useStore} from "vuex";
import routerData from "../../routerData";
import qs from "qs";
import axios from "axios";
const env = window.environment;
import keycloak from "../../app";

export default {
    name: "ScheduleTable",
    props: {
        token: String,
        requestUrl: String,
        activePollingType: String

    },
    setup(props) {
        const requestUrl = ref(props.requestUrl);
        const token = ref(props.token);
        const withLE = ref(props.withLE);
        const store = useStore();
        const router = useRouter();
        const route = useRoute();


        watch(
              () => props.token,
              (newVal) => {
                  token.value = newVal;
              }
        );
        watch(
              () => props.activePollingType,
              () => {

                  initTabulator()
              }
        );
        store.state.filterSG = "";
        const tableRef = ref();
        const tabulator = ref();
        const filter = reactive({
            field: "name",
            type: "like",
            value: store.state.filterSG,
        });
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: requestUrl.value,
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        Authorization: "bearer " + token.value,

                        "Content-type": "application/json; charset=utf-8",
                    },
                },
                ajaxResponse: async function (url, params, response) {
                    let data = [];
                    const res = response.data;
                    let field = props.activePollingType + 'Polling'

                    for (let item in res[field]) {

                        let tempItem = {
                            supplierGroupId: item,
                            legalEntities: [],
                            logo: 'no-logo'
                        }


                        let tempLegalEntities = [];
                        store.state.supplierGroups.forEach(sg => {
                            if (sg.supplierGroupId == item) {
                                tempLegalEntities = sg.supplierLegalEntities;

                                if (sg.channelsConfig && sg.channelsConfig.docImage && sg.channelsConfig.docImage.configPortal && sg.channelsConfig.docImage.configPortal.logo) {
                                    tempItem.logo = sg.channelsConfig.docImage.configPortal.logo
                                }
                            }
                        })


                        res[field][item].forEach(le => {
                            let name = tempLegalEntities[le].internalName ? tempLegalEntities[le].internalName : tempLegalEntities[le].name;
                            name = ' ' + name
                            tempItem.legalEntities.push(name);

                        })

                        data.push(tempItem);


                    }


                    let formatedData = data;
                    return formatedData; //return the tableData property of a response json object
                },
                // ajaxURLGenerator: function (url) {
                //     console.log("generator")
                //     updateToken();
                //     return url
                // },

                ajaxContentType: "json",
                ajaxFiltering: false,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
				pagination: "local",
                paginationSize: 10,
                layout: "fitColumns",
                reactiveData: true,
                responsiveLayout: "collapse",
                placeholder: "No matching records found",
                columns: [

                    {
                        title: "SUPPLIER GROUP",
                        field: "supplierGroupId",
                        width: "450",

                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                          <div class="logo-container">
                                <div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center logo">
                                    <img class="running-job-logo ${cell.getData().logo}" src="${cell.getData().logo}">
                             </div>
                                ${cell.getData().supplierGroupId}
                          </div>
                    </div>`;
                        },
                    },
                    {
                        title: "LEGAL ENTITIES",
                        field: "legalEntities",

                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                          <div>${cell.getData().legalEntities}</div>
                    </div>`;
                        },
                    },


                ],
            });
            onFilter();
        };
        const tokenIsExpired = () => {

            let exp;
            if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
                exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp);
            }
            let now = parseInt(new Date().getTime() / 1000);
            if (exp - now <= 0) {
                return true
            } else {
                return false
            }
        }

        const updateToken = () => {
            console.log("UpdateToken")

            if (tokenIsExpired()) {
                let payload = {
                    client_id: env.keycloakClient,
                    grant_type: "refresh_token",
                    refresh_token: store.state.refreshToken
                }

                let body = qs.stringify(payload);
                axios.post(store.state.API.keycloakToken,
                      body,
                      {
                          headers: {
                              'Content-Type': "application/x-www-form-urlencoded",

                          }
                      }
                )
                      .then(res => {
                          store.commit('setToken', res.data.access_token);
                          store.commit('setRefreshToken', res.data.refresh_token);
                          initTabulator();
                      })
                      .catch(() => {
                          store.commit('displayErrorAlert', true)
                          store.commit('displayErrorAlertText', "Session is expired")
                      })

                // keycloak.updateToken(30)
                //       .then(function (refreshed) {
                //           if (refreshed) {
                //               store.dispatch('setToken', keycloak.token)
                //               initTabulator();
                //               selectAllDocuments();
                //           }
                //       })
                //       .catch(function () {
                //
                //           store.dispatch("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
                //           store.dispatch('displayNotification', true);
                //       })
            }

        }
        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener("resize", () => {
                tabulator.value.redraw();
                feather.replace({
                    "stroke-width": 1.5,
                });
            });
        };
        // Filter function
        const onFilter = () => {
            store.state.filterSG = filter.value;
            tabulator.value.setFilter([
                [
                    {field: "name", type: filter.type, value: filter.value},
                    {field: "supplierGroupId", type: filter.type, value: filter.value},
                ],
            ]);
            /* tabulator.value.setFilter("name", filter.type, filter.value) */
        };

        // On reset filter
        const onResetFilter = () => {
            filter.field = "name";
            filter.type = "like";
            filter.value = "";
            onFilter();
        };

        onBeforeMount(() => {
            updateToken();
        }),

        onMounted(() => {
            initTabulator();
            reInitOnResizeWindow();
            if (withLE.value) {
                tabulator.value.addColumn(
                      {
                          title: "BANK",
                          field: "bank",
                          hozAlign: "center",
                          formatter(cell) {
                              return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                    cell.getData().bank
                              }"></div>
                    </div>`;
                          },
                      },
                      false, "terms"
                );
            }
        });

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
        };
    },
}
</script>

<style scoped>
.tabulator-arrow {
    display: none;
}

.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}

.summary-true::before {
    color: green;
    content: "✓";
}

.summary-false::before {
    color: red;
    content: "⨯";
    font-size: larger;
}


</style>
