<template>
	<div>
		<!-- BEGIN: HTML Table Data -->
		<div class="intro-y box p-5">
			<div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
				<form
					@submit.prevent="onFilter"
					id="tabulator-html-filter-form"
					class="xl:flex sm:mr-auto"
				>
					<div class="sm:flex items-center mt-2 xl:mt-0">
						<input
							id="tabulator-html-filter-value"
							v-model="filter.value"
							type="text"
							class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
							placeholder="Search..."
							@keyup="onFilter"
						/>
					</div>
					<div class="mt-2 xl:mt-0">
						<button
							id="tabulator-html-filter-reset"
							type="button"
							class="btn w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
							@click="onResetFilter"
						>
							Reset
						</button>
					</div>
				</form>
				<div class="" style="display: flex; flex-direction: row; margin: 5px">
					<button
						v-if="this.$store.state.currentUser.group !== 'NymusViewer'"
						type="button"
						class="btn w-full bg-theme-1 text-white" style="width: 100px; margin-right: 5px"
						@click="onCreateLE"
					>
						Create LE
					</button>
					<button
						v-if="this.$store.state.currentUser.group !== 'NymusViewer'"
						type="button"
						class="btn w-full bg-theme-1 text-white" style="width: 250px"
						@click="createAPIExpressEnvironment"
					>
						Create Environment API Express
					</button>
				</div>
				<div class="flex mt-5 sm:mt-0"></div>
			</div>
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				></div>
			</div>
		</div>

		<confirmation-with-props
			:description="'Are you sure you want to delete ' + showDelete + '?'"
			:show-dialog="showDelete !== ''"
			@cancel-action="showDelete = ''"
			@approve-action="deleteLe"
		></confirmation-with-props>
		<!-- END: HTML Table Data -->
	</div>
	<AddLegalEntity @trigger="onFilter"/>
	<AddEnvironmentAPIExpress
		:show-popup="displayCreateEnvironmentAPIExpress"
		@added-environment="onFilter"
		@close-popup="closePopupEnvironment"
	/>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import feather from "feather-icons";
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import cash from "cash-dom";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import routerData from "../routerData";
import AddLegalEntity from "./AddLegalEntity/AddLegalEntity.vue"
import AddEnvironmentAPIExpress from "@/components/AddEnvironmentAPIExpress/AddEnvironmentAPIExpress.vue";
import Confirmation from "@/components/ConfirmationDialogWithProps.vue";
import ConfirmationWithProps from "@/components/ConfirmationDialogWithProps.vue";

export default defineComponent({
	props: {
		token: String,
		requestUrl: String,
	},
	components: {
		ConfirmationWithProps,
		AddEnvironmentAPIExpress,
		AddLegalEntity
	},
	setup(props) {
		const selectedDocuments = ref([])
		const requestUrl = ref(props.requestUrl)
		const token = ref(props.token)
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const supplierGroupId = ref(route.params.id)
		const displayCreateEnvironmentAPIExpress = ref(false)
		const showDelete = ref('')
		watch(() => props.token, (newVal) => {
				token.value = newVal
			}
		);
		watch(() => store.state.legalEntityCreated, (newVal) => {
			if (newVal) {
				tabulator.value.redraw(true)
				reInitOnResizeWindow()
			}
			store.commit("legalEntityCreated", false)
		})
		let isInTestEnvironment = computed(() => {
			return window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')
		})

		const tableRef = ref()
		const tabulator = ref()
		const tableColumns = ref([
			{
				title: "",
				field: "logo",
				width: "150",
				hozAlign: "right",
				formatter(cell) {
					return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-start">
                        				<img class="object-contain h-6 w-auto" alt="No logo"  class="object-cover" src="${cell.getData().logo}"/>
                   					</div>`;
				},
			},
			{
				title: "ENTITY_NUMBER",
				field: "entityNumber",
				hozAlign: "center",
				formatter(cell) {
					return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                         				<div>${cell.getData().entityNumber}</div>
                    				</div>`
				},
			},
			{
				title: "NAME",
				field: "name",
				hozAlign: "center",
				formatter(cell) {
					return `<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                        				  <div>${cell.getData().name}</div>
                    				</div>`
				},
			},
			{
				title: "INTERNAL NAME",
				field: "name",
				hozAlign: "center",
				formatter(cell) {
					return `<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          				<div>${cell.getData().internalName}</div>
                    				</div>`
				},
			},
			{
				title: "BRANDING ID",
				field: "brandingId",
				hozAlign: "center",
				formatter(cell) {
					return `<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          				<div>${cell.getData().brandingId}</div>
                    				</div>`
				},
			}
		])
		if (isInTestEnvironment.value) {
			tableColumns.value.push({
				title: "",
				field: "deleteLe",
				hozAlign: "center",
				width: "100",
				formatter(cell) {
					const a = cash(`<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          						<button class="btn w-full nymus-orange color-white">Delete</button>
                    						</div>`)
					cash(a).on('click', function () {
						store.state.tabulator.isClickBlocked = true
						showDelete.value = cell.getData().entityNumber
					})
					return a[0]

				},
			})
		}
		const pageSize = ref(10)
		const filterValue = ref("")
		const timeout = ref(null)
		const filter = reactive({
			field: "name",
			type: "like",
			value: ""
		})

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						Authorization: "bearer " + token.value,
						Accept: "application/json",
					},
				},
				ajaxParams: {
					supplierGroupId: supplierGroupId.value,
					pageNo: 1,
					pageSize: 20,
					quick: filterValue.value,
				},
				ajaxResponse: async function (url, params, response) {
					let data = [];
					const res = response.data.legalEntities
					let index = 0
					for (let item of Object.values(res)) {
						data.push({
							entityNumber: item.entityNumber,
							name: item.name,
							internalName: item.internalName ? item.internalName : '',
							brandingId: item.brandingId ? item.brandingId : ''
						});
						if (item.legalEntityConfig) {
							if (item.legalEntityConfig.logo) {
								data[index].logo = item.legalEntityConfig.logo
							}
						}
						index++
					}
					let last_page = Math.ceil(response.data.total / pageSize.value)
					return {
						last_page: last_page,
						data: data,
					} //return the tableData property of a response json object
				},
				dataSendParams: {
					page: "pageNo",
					size: "pageSize",
					filter: "quick",
				},
				ajaxURLGenerator: function (url, config, params) {
					let filters = this.getFilters();
					if (filters.length > 0) {
						params.quick = filters[0]["value"];
					}
					return url;
				},
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				layout: "fitColumns",
				reactiveData: true,
				responsiveLayout: "collapse",
				placeholder: "No matching records found",
				columns: tableColumns.value
			})

			tabulator.value.on('rowClick', (_, row) => {
				setTimeout(() => {
					if (store.state.tabulator.isClickBlocked === false) {
						store.state.activeLE = Object.values(store.state.activeSG.supplierLegalEntities)
							.find((el) => el.entityNumber === row._row.data.entityNumber)
						router.push(routerData.SGOverview.link + "/" + supplierGroupId.value + "/" + row._row.data.entityNumber)
					} else store.state.tabulator.isClickBlocked = false
				})
			})
		}

		// Redraw table onresize
		const reInitOnResizeWindow = () => {
			window.addEventListener("resize", () => {
				tabulator.value.redraw();
				feather.replace({
					"stroke-width": 1.5,
				})
			})
		}
		// Filter function
		const onFilter = () => {
			clearTimeout(timeout.value)
			filterValue.value = filter.value
			tabulator.value.setFilter(filter.field, filter.type, filter.value)
			tabulator.value.redraw()
		};

		// On reset filter
		const onResetFilter = () => {
			filter.field = "name"
			filter.type = "like"
			filterValue.value = ""
			filter.value = ""
			onFilter()
		}

		const onCreateLE = () => {
			store.state.displayAddLegalEntity = true
		}
		const createAPIExpressEnvironment = () => {
			displayCreateEnvironmentAPIExpress.value = true
		}
		const closePopupEnvironment = () => {
			displayCreateEnvironmentAPIExpress.value = false
		}
		// Export
		const onExportCsv = () => {
			tabulator.value.download("csv", "data.csv");
		};
		const onExportJson = () => {
			tabulator.value.download("json", "data.json");
		}
		const onExportHtml = () => {
			tabulator.value.download("html", "data.html", {
				style: true,
			})
		}

		// Print
		const onPrint = () => {
			tabulator.value.print()
		}

		onMounted(() => {
			initTabulator()
			reInitOnResizeWindow()
		})

		const deleteLe = () => {
			store.dispatch("deleteLegalEntity", {
				legalEntityNumber: showDelete.value,
				supplierGroupId: supplierGroupId.value
			})
			showDelete.value = ''
			initTabulator()
		}

		return {
			tableRef,
			filter,
			showDelete,
			deleteLe,
			onFilter,
			onCreateLE,
			onResetFilter,
			onExportCsv,
			onExportJson,
			onExportHtml,
			onPrint,
			selectedDocuments,
			createAPIExpressEnvironment,
			closePopupEnvironment,
			displayCreateEnvironmentAPIExpress
		}
	}
})
</script>