<template>
	<div class="w-full">
		<div class="content">
			<div class="intro-y  flex flex-col col-span-12   mt-5 box md:col-span-6 lg:col-span-4">
				<div class="flex flex-row w-full">
					<div class="w-full text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 min-height-600">
						<p class="font-bold form-label mb-2">Peppol identifiers (each identifier in the new line)*:"</p>
						<div class="w-1/2">
                            <textarea
								rows="8"
								id="deleteFromLegalArchiveDocIds"
								v-model.trim="identifiers"
								@click="setErrors('identifiers', false)"
								class="textarea-style"
							/>
						</div>
						<p class="error" v-if="errors.identifiers">Identifiers field can not be empty</p>
						<div class="mt-2 w-1/2 button-container">
							<button
								@click="getParticipants"
								:disabled="disableButton"
								:style="{cursor: disableButton ? 'not-allowed' : 'pointer'}"
								class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1"
							>
								View participants
							</button>
						</div>
						<div v-if="this.$store.state.peppolDataRetrieved">
							<div class="w-1/2 one-item" v-if="!this.displayTable">
								No info
							</div>
							<div v-if="this.displayTable">
								<smp-participants-table :tableData=this.smpParticipants @enable-button="disableButton = false">
								</smp-participants-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import SmpParticipantsTable from "../components/Peppol/SmpParticipantsTable"

export default {
	name: "PeppolPage",
	components: {SmpParticipantsTable},
	data() {
		return {
			identifiers: '',
			errors: {
				identifiers: false
			},
			selectedIdentifier: '',
			displayTable: false,
			buttonIsClicked: false,
			disableButton: false
		}
	},
	mounted() {
		this.$store.state.peppolDataRetrieved = false
	},
	computed: {
		smpParticipants() {
			this.$store.state.smpParticipants.forEach(participant => {
				if (participant.documentType === 'UNKNOWN') participant.documentType = participant.documentIdentifier
			})
			return this.$store.state.smpParticipants
		}
	},
	watch: {
		smpParticipants(newVal) {
			this.displayTable = !!newVal.length
			if (this.$store.state.peppolDataRetrieved && !this.displayTable) this.disableButton = false
		}
	},
	methods: {
		setErrors(field, value) {
			this.errors[field] = value
		},
		async getParticipants() {
			this.disableButton = true
			this.$store.state.peppolDataRetrieved = false
			if (this.identifiers === '') {
				this.setErrors('identifiers', true)
				this.disableButton = false
				return
			}
			let peppolIdentifiers = this.identifiers.toLowerCase().split("\n")
			let peppolIdentifiersFiltered = []

			peppolIdentifiers.forEach(item => {
				if (item !== '') {
					peppolIdentifiersFiltered.push(item.trim())
				}
			})
			await this.$store.dispatch('peppol/getSmpParticipants', {identifiers: peppolIdentifiersFiltered});
		}
	}
}
</script>

<style scoped>
.textarea-style {
	border: 1px solid rgb(226, 232, 240);
	border-radius: 4px;
	width: 100%;
}

.textarea-style:focus, .textarea-style:focus-visible {
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important;
	border: 2px solid rgb(226, 232, 240) !important;
	outline-style: hidden !important;
}

.one-item {
	display: grid;
	grid-template-columns: 80px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: end;
}
</style>