<template>
	<pop-up-window
		:show="this.$store.state.displayEditPrint && !this.$store.state.displayAddPrintJob"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Print</template>
		<template #content>
			<div class="flex flex-col flex-nowrap">
				<div class="mb-3 flex">
					<label class="font-bold text-xl form-label mr-3 mb-0">Print:</label>
					<ToggleSlide
						class="w-min"
						@update-value="changePrintBool"
						:bool="printBool"
					/>
				</div>
				<div v-if="printBool">
					<Accordion
						:class="{
						'border-primary-3': printerNameInvalid || printLocationInvalid,
						'border-gray-300': !printerNameInvalid && !printLocationInvalid,
						}"
						class="mb-5 border p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-lg">Configurations</span>
						</template>
						<template v-slot:content>
							<div class="flex flex-row">
								<div class="flex flex-col w-1/2 mr-12">
									<div class="mb-3">
										<label class="font-bold form-label">Print Provider:</label>
										<Dropdown
											:data="printProviderData"
											:selected="formData.printProvider"
											@update-value="updatePrintProvider"
										/>

										<vue-date-picker
											v-if="providerIsEasypost"
											v-model="this.formData.printCalendar.printDates"
											multi-dates
											inline
											auto-apply
											:enable-time-picker="false"
											class="mt-3"
										/>
										<vue-date-picker
											style="width: 261px"
											class="mt-3"
											auto-apply
											v-if="providerIsEasypost"
											v-model="this.formData.printCalendar.cron"
											time-picker
										/>
									</div>
								</div>
							</div>
						</template>
					</Accordion>
					<div class="flex">
						<p class="font-semibold text-lg">Jobs:</p>
						<button
							@click="displayAddPrintJob"
							class="btn bg-theme-1 py-0 text-white shadow-md mx-2"
						>
							Add
						</button>
					</div>
					<div v-if="formData.printJobs">
						<Accordion
							:key="item + index + formData.printProvider"
							v-for="(item, index) in formData.printJobs"
							class="mt-3 mb-2 border p-1 box"
						>
							<template v-slot:title>
								<span class="font-semibold text-lg">{{ item.name }}</span>
							</template>
							<template v-slot:content>
								<div class="flex flex-row">
									<div class="flex flex-col w-1/2 mr-12">
										<Field
											title="Name:"
											type="text"
											v-model="item.name"
										/>
										<Field
											type="text"
											title="Display name:"
											v-model="item.additional.displayName"
										/>
										<Field
											v-if="providerIsEasypost"
											type="text"
											title="Work flow ID:*"
											v-model="item.workFlowId"
										/>

										<div>
											<p class="font-bold py-2">
												Legal entities:
											</p>
											<DropdownSelectAll
												:selected=setSelectedLE(item.name)
												@update-value="updateLegalEntities($event, item.name)"
												:data="supplierLegalEntities"
												v-model="item.additional.legalEntityIds"
											/>
											<Field
												v-if="providerIsSpeos"
												title="Envelope template:"
												type="text"
												v-model="item.additional.envelopeTemplate"
												maxLength="50"
											/>
											<div class="mb-3 flex" v-if="providerIsSpeos">
												<label class="font-bold form-label mr-3 mb-0">Print correlation:</label>
											</div>
											<div
												v-if="boolComputed(item.additional, 'printCorrelation') && providerIsSpeos"
												class="ml-6"
											>
												<Field
													type="text"
													maxLength="10"
													title="ID:"
													:hasError="idInvalid"
													@input="validate"
													v-model="item.additional.printCorrelation.id"
												/>
												<span v-if="correlationIdInvalid" class="text-primary-3">
													Max length is 10 characters
												</span>

												<Field
													type="text"
													:hasError="correlationNameInvalid"
													@input="validate"
													maxLength="42"
													title="Name:"
													v-model="item.additional.printCorrelation.name"
												/>
											</div>
										</div>
									</div>
									<div class="flex flex-col w-1/2">
										<div v-if="providerIsSpeos" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">Prior mail:</label>
											<ToggleSlide
												@update-value="changeBoolValue($event, item, 'priorMail')"
												class="w-min"
												:key="item.priorMail"
												:bool="item.priorMail"
											/>
										</div>
										<div v-if="providerIsSpeos" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">Registered mail:</label>
											<ToggleSlide
												@update-value="changeBoolValue($event, item, 'registeredMail')"
												class="w-min"
												:key="item.registeredMail"
												:bool="item.registeredMail"
											/>
										</div>
										<!--SPEOS and/or EASYPOST-->
										<div>
											<div v-if="providerIsSpeos" class="mb-3 flex">
												<label class="font-bold form-label mr-3 mb-0">
													Print Billing Document Recto Verso:
												</label>
												<ToggleSlide
													@update-value="changeBoolValue($event,item,'billingDocumentRectoVerso' )"
													class="w-min"
													:key="item.billingDocumentRectoVerso"
													:bool="item.billingDocumentRectoVerso"
												/>
											</div>
											<div v-if="providerIsSpeos && item.registeredMail" class="mb-3 ml-6 flex">
												<label class="font-bold form-label mr-3 mb-0">
													Acknowledgement of receipt:
												</label>
												<ToggleSlide
													@update-value="changeBoolValue( $event,item, 'acknowledgementOfReceipt')"
													class="w-min"
													:key="item.additional"
													:bool="boolComputed(item.additional,'acknowledgementOfReceipt')"
												/>
											</div>
											<div v-if="providerIsSpeos" class="mb-3 flex">
												<label class="font-bold form-label mr-3 mb-0">
													Insert address page:
												</label>
												<ToggleSlide
													@update-value="changeBoolValue($event, item, 'insertAddressPage') "
													class="w-min"
													:key="item.additional"
													:bool="boolComputed(item.additional, 'insertAddressPage')"
												/>
											</div>
											<div
												v-if="providerIsSpeos || providerIsEasypost"
												class="mb-3 flex"
											>
												<label class="font-bold form-label mr-3 mb-0">
													Insert blank page after first page:
												</label>
												<ToggleSlide
													:class="{'pointer-events-none opacity-50': boolComputed(item.additional,'insertTermsAndConditionsAfterFirstPage') }"
													@update-value="changeBoolValue($event,item,'insertBlankPageAfterFirstPage')"
													class="w-min"
													:key="item.additional"
													:bool="boolComputed(item.additional,'insertBlankPageAfterFirstPage')"
												/>
											</div>
											<div
												v-if="providerIsSpeos || providerIsEasypost"
												class="mb-3 flex"
											>
												<label class="font-bold form-label mr-3 mb-0">Concatenate T&amp;C
													template:</label>
												<ToggleSlide
													@update-value=" changeBoolValue( $event, item,'concatenateTermsAndConditionsTemplate')"
													class="w-min"
													:key="item.additional"
													:bool="boolComputed(item.additional,'concatenateTermsAndConditionsTemplate')"
												/>
											</div>
											<div
												v-if="(providerIsSpeos || providerIsEasypost) && boolComputed( item.additional,'concatenateTermsAndConditionsTemplate' )"
												class="ml-6 mb-3 flex">
												<label class="font-bold form-label mr-3 mb-0">
													Insert T&amp;C after first page:
												</label>
												<ToggleSlide
													:class="{ 'pointer-events-none opacity-50':item.additional.insertBlankPageAfterFirstPage,}"
													@update-value="changeBoolValue( $event, item,'insertTermsAndConditionsAfterFirstPage')"
													class="w-min"
													:key="item.additional"
													:bool="boolComputed( item.additional,'insertTermsAndConditionsAfterFirstPage' )"
												/>
											</div>
											<div v-if="providerIsSpeos" class="mt-12 mb-3 flex">
												<label class="font-bold form-label mr-3 mb-0">Test mode:</label>
												<ToggleSlide
													@update-value="changeBoolValue($event, item, 'testMode')"
													class="w-min"
													:key="item.additional"
													:bool="boolComputed(item.additional, 'testMode')"
												/>
											</div>
										</div>
									</div>
								</div>
								<!--SPEOS-->
								<div
									v-if="providerIsSpeos"
									class="flex flex-col"
								>
									<div class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Return Address:</label>
										<ToggleSlide
											@update-value="changeBoolValue($event, item, 'returnAddress')"
											class="w-min"
											:key="item.additional"
											:bool="boolComputed(item.additional, 'returnAddress')"
										/>
									</div>
									<div
										v-if="boolComputed(item.additional, 'returnAddress')"
										class="flex flex-row"
									>
										<div class="flex flex-col w-1/2 mr-12 ml-6">
											<Field
												type="text"
												maxLength="50"
												title="Company name:"
												v-model="item.additional.returnAddress.companyName"
											/>
										</div>
										<div class="flex flex-col w-1/2"></div>
									</div>
									<div
										v-if="boolComputed(item.additional, 'returnAddress')"
										class="flex flex-row"
									>
										<div class="flex flex-col w-1/2 mr-12 ml-6">
											<Field
												type="text"
												@input="validate"
												title="Street:"
												v-model="item.additional.returnAddress.streetName"
											/>
											<Field
												type="text"
												maxLength="12"
												title="Zip:"
												v-model="item.additional.returnAddress.zip"
											/>
											<div class="mb-3">
												<label class="font-bold form-label">Country code:</label>
												<Dropdown
													@update-value="changeAddressCountryCode( $event,item.additional.returnAddress)"
													:data="languages"
													:key="item.additional.returnAddress.countryCode"
													:selected="item.additional.returnAddress.countryCode"
												/>
											</div>
										</div>
										<div class="flex flex-col w-1/2">
											<Field
												@input="validate"
												type="text"
												title="House number:"
												v-model="item.additional.returnAddress.houseNumber"
											/>
											<Field
												type="text"
												maxLength="30"
												title="City:"
												v-model="item.additional.returnAddress.city"
											/>
											<Field
												type="text"
												maxLength="42"
												title="Country name:"
												v-model="item.additional.returnAddress.countryName"
											/>
										</div>
									</div>
								</div>
								<div class="grid grid-rows-1 justify-items-end">
									<div>
										<button
											class="btn shadow-md bg-theme-1 text-white m-2"
											@click="showConfirmDialog(item)"
										>
											Delete
										</button>
									</div>
								</div>
							</template>
						</Accordion>
					</div>

					<span v-if="printJobsInvalid" class="text-primary-3">One printjob is required</span>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deletePrintJob" :show="showConfirm"/>
	<AddPrintJob
		v-if="printBool"
		@add-printjob="addPrintJob"
		:provider="formData.printProvider"
		:printColorData="printColorData"
		:printProviderData="printProviderData"
		:printEnvelopeTypeData="printEnvelopeTypeData"
		:languages="languages"
	/>
	<error-alert></error-alert>
	<new-alert
		v-if="channelChanged"
		title="Le channels are configured"
		:is-visible="channelChanged"
		text="There is a le with separate configurations. Check if still correct"
		style="margin-top: 30px"
	/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Accordion from "../Accordion.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import ConfirmDialog from "../ConfirmDialog.vue"
import AddPrintJob from "../AddSupplierGroup/AddPrintJob.vue"
import DropdownSelectAll from "../DropdownSelectAll.vue"
import ErrorAlert from "../ErrorAlert"
import NewAlert from "@/components/NewAlert.vue";
import VueDatePicker from "@vuepic/vue-datepicker"
import moment from "moment"

export default {
	name: "EditPrint",
	components: {
		VueDatePicker,
		NewAlert,
		ErrorAlert,
		PopUpWindow,
		Accordion,
		ToggleSlide,
		ConfirmDialog,
		Field,
		Dropdown,
		AddPrintJob,
		DropdownSelectAll: DropdownSelectAll,
	},
	props: {
		printProviderData: Array,
		printEnvelopeTypeData: Array,
		printColorData: Array,
		languages: Array,
	},

	data() {
		return {
			errors: {envelopType: false},
			printerNameInvalid: false,
			idInvalid: false,
			correlationNameInvalid: false,
			currentDelItem: {},
			showConfirm: false,
			printLocationInvalid: false,
			printJobsInvalid: false,
			days: [],
			printBool: !!this.$store.state.activeSG.channelsConfig.print,
			supplierGroup: this.$store.state.activeSG,
			formData: {
				printProvider: "",
				printerName: "",
				printLocation: "",
				printCredentials: {
					username: "",
					password: ""
				},
				printCalendar: {
					printDates: [],
					cron: ""
				},
				printJobs: JSON.parse(JSON.stringify(this.$store.state.printJobs)),
			},
			selectedPrintJob: '',
			channelChanged: false,
		}
	},
	mounted() {
		this.initData()
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		providerIsSpeos() {
			return this.formData.printProvider === 'SPEOS'
		},
		providerIsEasypost() {
			return this.formData.printProvider === 'EASYPOST'
		},
		supplierLegalEntities() {
			let leList = []
			for (const le in this.$store.state.activeSG.supplierLegalEntities) {
				let tempItem = {
					name: this.$store.state.activeSG.supplierLegalEntities[le].internalName ? this.$store.state.activeSG.supplierLegalEntities[le].internalName : this.$store.state.activeSG.supplierLegalEntities[le].name,
					value: le
				}
				leList.push(tempItem)
			}
			return leList
		},
		dates() {
			return this.days.map((day) => day.date)
		},
	},
	watch: {
		activeSG() {
			this.initData()
		}
	},
	methods: {
		setSelectedLE(printJob) {
			let printJobLe = []
			let foundPrintJob = this.formData.printJobs.find(job => job.name === printJob)

			if (foundPrintJob.additional && foundPrintJob.additional.legalEntityIds) {
				foundPrintJob.additional.legalEntityIds.forEach(id => {
					if (this.$store.state.activeSG.supplierLegalEntities[id].internalName) {
						printJobLe.push(this.$store.state.activeSG.supplierLegalEntities[id].internalName)
					} else printJobLe.push(this.$store.state.activeSG.supplierLegalEntities[id].name)
				})
			}
			return printJobLe
		},
		updateLegalEntities(data, selectedJob) {
			let leArray = []
			data.forEach((selectedLe) => leArray.push(this.supplierLegalEntities[selectedLe].value))

			let selectedPrintJob = this.formData.printJobs.find(job => job.name === selectedJob)
			if (!selectedPrintJob.additional) {
				selectedPrintJob.additional = {}
				selectedPrintJob.additional.legalEntityIds = []
			}
			if (selectedPrintJob.additional && !selectedPrintJob.additional.legalEntityIds) {
				selectedPrintJob.additional.legalEntityIds = []
			}
			selectedPrintJob.additional.legalEntityIds = leArray
		},
		/* CONFIGURATIONS */
		changePrintBool(data) {
			this.printBool = data
		},
		updatePrintProvider(data) {
			this.formData.printProvider = this.printProviderData[data].name
			if (this.printProviderData[data].name === "SPEOS") {
				for (let item of this.formData.printJobs) {
					if (!item.additional) item.additional = {}
				}
			}
		},

		/*PRINT JOB */
		addPrintJob(payload) {
			this.formData.printJobs.push(payload)
			this.validate()
		},
		changePrintColor(data, item) {
			if (data === "\u00a0" || data[0] === -1) {
				item.color = ""
			} else {
				item.color = this.printColorData[data].name
			}
		},
		changeEnvelopeType(data, item) {
			this.errors.envelopType = false
			item.envelopeType = this.printEnvelopeTypeData[data].name
		},
		changeAddressCountryCode(data, address) {
			address.countryCode = this.languages[data].name
		},
		showConfirmDialog(item) {
			this.currentDelItem = item
			this.showConfirm = true
		},
		deletePrintJob(data) {
			if (data === "Yes") {
				let index = this.formData.printJobs.indexOf(this.currentDelItem)
				if (index > -1) {
					this.formData.printJobs.splice(index, 1)
				}
			}
			this.showConfirm = false
		},
		/*PRINT JOB BOOLS */
		changeBoolValue(data, object, isFor) {
			switch (isFor) {
				case "printCorrelation":
					if (data) {
						if (!object.additional) object.additional = {}
						object.additional.printCorrelation = {name: "", id: ""}
					} else object.additional.printCorrelation = null
					break
				case "returnAddress":
					if (data) {
						if (!object.additional) object.additional = {}
						object.additional.returnAddress = {
							zip: "",
							city: "",
							streetName: "",
						}
					} else object.additional.returnAddress = null
					break
				case "priorMail":
					object.priorMail = data
					break
				case "registeredMail":
					object.registeredMail = data
					if (this.formData.printProvider === "SPEOS" && !data) {
						object.additional.acknowledgementOfReceipt = false
					}
					break
				case "billingDocumentRectoVerso":
					object.billingDocumentRectoVerso = data
					break
				case "attachmentsRectoVerso":
					object.attachmentsRectoVerso = data
					break
				case "acknowledgementOfReceipt":
					object.additional.acknowledgementOfReceipt = data
					break
				case "insertAddressPage":
					object.additional.insertAddressPage = data
					break
				case "insertBlankPageAfterFirstPage":
					object.additional.insertBlankPageAfterFirstPage = data
					break
				case "concatenateTermsAndConditionsTemplate":
					object.additional.concatenateTermsAndConditionsTemplate = data
					if (this.formData.printProvider === "SPEOS" && !data) {
						object.additional.insertTermsAndConditionsAfterFirstPage = false
					}
					break
				case "insertTermsAndConditionsAfterFirstPage":
					object.additional.insertTermsAndConditionsAfterFirstPage = data
					break
				case "testMode":
					object.additional.testMode = data
					break
			}
		},
		/* INIT & POPUP BUTTONS */
		initData() {
			this.printBool = !!this.$store.state.activeSG.channelsConfig.print
			this.printerNameInvalid = false
			this.correlationNameInvalid = false
			this.idInvalid = false
			this.printJobsInvalid = false
			this.currentDelItem = {}
			this.showConfirm = false
			this.days = []
			let printJobs = this.$store.state.activeSG.channelsConfig.print?.printJobs
				? this.$store.state.activeSG.channelsConfig.print.printJobs : []
			this.$store.state.printJobs = [...printJobs]
			this.printLocationInvalid = false
			if (!this.formData) this.formData = {}

			this.formData.printProvider = this.$store.state.activeSG.channelsConfig.print
				? this.$store.state.activeSG.channelsConfig.print.printProvider : "SPEOS"
			this.formData.printerName = this.$store.state.activeSG.channelsConfig.print
				? this.$store.state.activeSG.channelsConfig.print.printerName : ""
			this.formData.printLocation = this.$store.state.activeSG.channelsConfig.print
				? this.$store.state.activeSG.channelsConfig.print.printLocation : ""

			this.formData.printCredentials = {
				username: this.$store.state.activeSG.channelsConfig.print?.printCredentials
					? this.$store.state.activeSG.channelsConfig.print.printCredentials.username : "",
				password: this.$store.state.activeSG.channelsConfig.print?.printCredentials
					? this.$store.state.activeSG.channelsConfig.print.printCredentials.password : ""
			}

			let cron = ""
			if (this.$store.state.activeSG.channelsConfig.print?.printCalendar?.cron) {
				let time = this.$store.state.activeSG.channelsConfig.print.printCalendar.cron.split(' ')
				cron = {
					hours: time[2],
					minutes: time[1],
					seconds: time[0]
				}
			}

			this.formData.printCalendar = {
				printDates: this.$store.state.activeSG.channelsConfig.print?.printCalendar
					? this.$store.state.activeSG.channelsConfig.print.printCalendar.printDates : [],
				cron: cron ? cron : "",
			}

			this.formData.printJobs = JSON.parse(JSON.stringify(this.$store.state.printJobs))

			this.formData.printJobs.forEach(job => {
				if (!(job.additional)) job.additional = {displayName: ''}
				if (!(job.additional.displayName)) job.additional.displayName = ''
			})

			for (let date in this.formData.printCalendar.printDates) {
				this.days.push({
					id: parseInt(this.formData.printCalendar.printDates[date].slice(-2)),
					date: this.formData.printCalendar.printDates[date],
				})
			}
		},
		displayAddPrintJob() {
			this.$store.state.displayAddPrintJob = true
		},
		close() {
			this.initData()
			this.$store.state.displayEditPrint = false
		},
		async update() {
			for (const printJob in this.formData.printJobs) {
				if (this.formData.printJobs[printJob].envelopeType === undefined || this.formData.printJobs[printJob].envelopeType === 'No envelop type') {
					delete this.formData.printJobs[printJob].envelopeType
				}
			}
			let hasErrors = this.validation()
			if (hasErrors) {
				this.$store.commit('displayErrorAlert', true)
			} else {
				let leSettingsOn = false
				Object.values(this.$store.state.activeSG.supplierLegalEntities).forEach(element => {
					if (element.channelsConfig !== undefined && Object.keys(element.channelsConfig).length > 0) leSettingsOn = true
				})
				if (leSettingsOn && this.printBool !== (this.$store.state.activeSG.channelsConfig.print !== undefined)) {
					this.channelChanged = true
					setTimeout(() => {
						this.channelChanged = false
					}, 5000)
				}
				for (let item in this.formData.printJobs) {
					if (this.formData.printProvider === "SPEOS") {
						delete this.formData.printCalendar
						this.formData.printerName = "SPEOS"
						this.formData.printLocation = "/SPEOS"
						delete this.formData.printCredentials
						delete this.formData.printJobs[item].attachmentsPaperType
						delete this.formData.printJobs[item].attachmentsRectoVerso
						delete this.formData.printJobs[item].billingDocumentPaperType
						delete this.formData.printJobs[item].color
						delete this.formData.printJobs[item].foldingName
						if (!this.formData.printJobs[item].additional) delete this.formData.printJobs[item].additional
						if (this.formData.printJobs[item].additional?.envelopeTemplate === '') {
							delete this.formData.printJobs[item].additional.envelopeTemplate
						}
						if (this.formData.printJobs[item].additional.returnAddress) {
							if (!this.formData.printJobs[item].additional.returnAddress.countryCode) {
								this.formData.printJobs[item].additional.returnAddress.countryCode = "AD"
							}
						}

						if (this.formData.printJobs[item].additional?.legalEntityIds?.length !== 0) {
							this.formData.printJobs[item].additional.legalEntityIds = [...new Set(this.formData.printJobs[item].additional.legalEntityIds)]
						}
						if (this.formData.printJobs[item].additional && this.formData.printJobs[item].additional.legalEntityIds && this.formData.printJobs[item].additional.legalEntityIds.length === 0) {
							delete this.formData.printJobs[item].additional.legalEntityIds
						}
					}
					if (this.formData.printProvider === "EASYPOST") {
						// delete settings that are not used by easypost
						delete this.formData.printCredentials
						this.formData.printerName = "EASYPOST"
						this.formData.printLocation = "/EASYPOST"
						if (this.formData.printJobs[item].additional?.legalEntityIds?.length !== 0) {
							this.formData.printJobs[item].additional.legalEntityIds = [...new Set(this.formData.printJobs[item].additional.legalEntityIds)]
						} else delete this.formData.printJobs[item].additional.legalEntityIds

						delete this.formData.printJobs[item].attachmentsPaperType
						delete this.formData.printJobs[item].attachmentsRectoVerso
						delete this.formData.printJobs[item].billingDocumentPaperType
						delete this.formData.printJobs[item].billingDocumentRectoVerso
						delete this.formData.printJobs[item].color
						delete this.formData.printJobs[item].foldingName
						delete this.formData.printJobs[item].envelopeType
						delete this.formData.printJobs[item].priorMail
						delete this.formData.printJobs[item].registeredMail

						delete this.formData.printJobs[item].additional.insertAddressPage
						delete this.formData.printJobs[item].additional.acknowledgementOfReceipt
						delete this.formData.printJobs[item].additional.printCorrelation
						delete this.formData.printJobs[item].additional.testMode
						delete this.formData.printJobs[item].additional.envelopeTemplate
					}
				}
				if (this.formData.printProvider === "EASYPOST" && this.formData.printCalendar.cron) {
					this.formData.printCalendar.cron =
						this.formData.printCalendar.cron.seconds + ' ' +
						this.formData.printCalendar.cron.minutes + ' ' +
						this.formData.printCalendar.cron.hours + " ? * * *"
				}
				if (!this.printBool) this.formData = null

				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					edit: "print-settings",
					body: {print: this.formData},
				}
				await this.$store.dispatch("EditSupplierGroup", payload)
			}
		},
		validation() {
			let hasErrors = false
			let text = "TEST"
			this.idInvalid = false
			this.correlationNameInvalid = false

			this.formData.printJobs.forEach((printJob, index) => {
				if (printJob.name === '') {
					let nameIndex = index + 1
					text = "New name of print job " + nameIndex + " can not be empty"
					hasErrors = true
				}
				if (this.providerIsEasypost && printJob.workFlowId === '') {
					text = "New work flow ID of print job " + printJob.name + " can not be empty"
					hasErrors = true
				}
				if (printJob.additional.printCorrelation && this.formData.printProvider === "SPEOS") {
					if (printJob.additional.printCorrelation.id.trim() === "") {
						text = "ID is required"
						hasErrors = true
						this.idInvalid = true
					} else if (printJob.additional.printCorrelation.id.length > 10) {
						text = "Max length is 10 characters"
						hasErrors = true
						this.idInvalid = true
					}
				}

				if (printJob.additional.printCorrelation && this.formData.printProvider === "SPEOS") {
					if (printJob.additional.printCorrelation.name.trim() === "") {
						text = "Name is required"
						hasErrors = true
						this.correlationNameInvalid = true
					}
				}

				if (printJob.additional?.returnAddress) {
					let addressLength = 0
					if (printJob.additional.returnAddress.streetName) {
						addressLength = addressLength + printJob.additional.returnAddress.streetName.length
					}
					if (printJob.additional.returnAddress.houseNumber) {
						addressLength = addressLength + printJob.additional.returnAddress.houseNumber.length
					}

					if (addressLength > 50) {
						let nameIndex = index + 1
						text = "New address of print job " + nameIndex + " is too long"
						hasErrors = true
					}
				}
			})

			if (hasErrors) {
				this.$store.commit('setErrorAlertText', text)
			}
			return hasErrors
		},
		validate() {
			let hasErrors = false
			this.printerNameInvalid = false
			this.printLocationInvalid = false
			this.printJobsInvalid = false

			let hasFoundName = Object.values(this.formData.printJobs).find(el => el.name.length === 0)

			if (hasFoundName) {
				hasErrors = true
			}

			if (this.formData.printJobs.length === 0) {
				hasErrors = true
				this.printJobsInvalid = true
			}

			let hasFound = Object.values(this.formData.printJobs).find((el) => {
				let streetLength = el.additional
					? el.additional.returnAddress
						? el.additional.returnAddress.streetName
							? el.additional.returnAddress.streetName
								? el.additional.returnAddress.streetName.length
								: 0
							: 0
						: 0
					: 0
				let houseNumberLength = el.additional?.returnAddress
					? el.additional.returnAddress.streetName
						? el.additional.returnAddress.houseNumber?.length
						: 0
					: 0
				let totalLengthStreetNumber = streetLength + houseNumberLength + 1
				if (totalLengthStreetNumber > 50) return el
			})
			return hasErrors
		},
		/* PRIVATE METHODS */
		getArr(sum, startAt1 = false) {
			let arr = []
			if (startAt1) {
				for (let index = 1; index < sum + 1; index++) {
					arr.push({name: index.toString()})
				}
				return arr
			}
			for (let index = 0; index < sum; index++) {
				arr.push({name: index.toString()})
			}
			return arr
		},
		boolComputed(object, isFor) {
			let bool = false
			if (object) {
				switch (isFor) {
					case "printCorrelation":
						bool = !!object.printCorrelation
						break
					case "returnAddress":
						bool = !!object.returnAddress
						break
					case "acknowledgementOfReceipt":
						bool = !!object.acknowledgementOfReceipt
						break
					case "insertAddressPage":
						bool = !!object.insertAddressPage
						break
					case "insertBlankPageAfterFirstPage":
						bool = !!object.insertBlankPageAfterFirstPage
						break
					case "concatenateTermsAndConditionsTemplate":
						bool = !!object.concatenateTermsAndConditionsTemplate
						break
					case "insertTermsAndConditionsAfterFirstPage":
						bool = !!object.insertTermsAndConditionsAfterFirstPage
						break
					case "testMode":
						bool = !!object.testMode
						break
				}
			}
			return bool
		}
	}
}
</script>

<style scoped>

.dp__action_row {
	display: none;
}

</style>