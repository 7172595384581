<template>
    <div v-if="this.$store.state.displayLoader" class="loader">
        <div class="header nymus-blue">

        </div>
        <div class="loading-text">
           <img src="../assets/images/logo.png">
            <h1>Data is loading
                <span class="points points-0">.</span>
                <span class="points points-1">.</span>
                <span class="points points-2">.</span></h1>
        </div>

    </div>

</template>

<script>
export default {
    name: "pageLoader"
}
</script>

<style scoped>
.loader{
    height: 200px;
    width: 400px;
    background-color: white;
    top: 30%;
    left: 50%;
    border-radius: 6px;
    z-index: 200;
    position: fixed;
    border: 1px solid #bfbebe;
}

.header{
    height: 60px;
    border-radius: 6px 6px 0 0;
}
.loading-text{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 80px auto;
    grid-auto-flow: row;
    align-items: center;
    justify-items: center;
    padding: 40px 23% 0 23%;
}
.loading-text img{
    height: 60px;
}
.loading-text h1{
    font-size: 16px;
}

.points{
    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes loading{
    from {opacity : 1}
    to {opacity : 0.1}

}
.points-0{
    animation-delay: 0s;
}
.points-1{
    animation-delay: 0.2s;
}
.points-2{
    animation-delay: 0.4s;
}

</style>
