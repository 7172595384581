<template>
	<div class="w-5/6">
		<div class="content">
			<div class="col-span-12 mt-5 md:col-span-6 lg:col-span-4">
				<div class="flex justify-between">
					<p class="back ml-2 font-bold text-4xl" @click="goBack()">&#171;</p>
					<div class="flex">
						<button
							:class="{'bg-gray-500' : qsIngesionClicked,'bg-theme-1': !qsIngesionClicked}"
							:disabled="qsIngesionClicked"
							class="btn shadow-md text-white mr-6"
							@click="qsIngestion"
						>
							Ingestion
						</button>
						<p
							v-if="this.$store.state.currentUser.group !== 'NymusViewer'"
							class="back ml-2 font-bold text-3xl"
							@click="view(selectedTabName)"
						>
							<font-awesome-icon icon="pencil"/>
						</p>
					</div>

				</div>
				<div style="display: flex; flex-direction: row; justify-content: center">
					<h1 class="font-bold text-2xl text-center">{{ supplierGroup.name }}</h1>
					<span class="material-icons" style="font-size: 30px">arrow_forward</span>
					<h1 class="font-bold text-2xl text-center">
						{{ legalEntity.internalName ? legalEntity.internalName : legalEntity.name }}
					</h1>
				</div>

				<div>
					<tabs v-model="selectedTab">
						<tab
							class="tab"
							v-for="(tab, i) in tabs"
							:key="`t${i}`"
							:val="tab"
							:label="tab"
							:indicator="true"
						/>
					</tabs>
					<tab-panels v-model="selectedTab" :animate="false">
						<tab-panel val="General">
							<ViewGeneral/>
						</tab-panel>
						<tab-panel val="Ingestion">
							<ViewIngestion/>
						</tab-panel>
						<tab-panel val="Channels">
							<ViewChannels/>
						</tab-panel>
						<tab-panel val="Peppol Documents">
							<ViewPeppolDocuments isFor="legalEntity"/>
						</tab-panel>
						<tab-panel val="Payment">
							<ViewPayment :paymentProvider="paymentProvider"/>
						</tab-panel>
						<tab-panel val="Templates">
							<tabs v-model="selectedSubTabTemplates">
								<tab
									class="tab"
									v-for="(tab, i) in tabsTemplate"
									:key="`t${i}`"
									:val="tab"
									:label="tab"
									:indicator="true"
								/>
							</tabs>
							<tab-panels v-model="selectedSubTabTemplates" :animate="false">
								<tab-panel val="Mail">
									<ViewMailTemplate isFor="legalEntity" :languages="languages"/>
								</tab-panel>
								<tab-panel val="QR">
									<ViewQrTemplate isFor="legalEntity" :qrOverlayData="qrOverlayData"/>
								</tab-panel>
								<tab-panel val="Overlay">
									<ViewOverlayTemplate isFor="legalEntity" :overlayOverlayData="overlayOverlayData"/>
								</tab-panel>
								<tab-panel val="Terms and Conditions">
									<ViewTac isFor="legalEntity"/>
								</tab-panel>
								<tab-panel val="Pre-Ingestion">
									<ViewPreIngestion isFor="legalEntity"/>
								</tab-panel>
								<tab-panel val="Resend">
									<ViewResendTemplate isFor="legalEntity"/>
								</tab-panel>
								<tab-panel val="Inserts">
									<ViewInserts isFor="legalEntity"/>
								</tab-panel>
							</tab-panels>
						</tab-panel>
						<tab-panel val="Bank Accounts">
							<ViewBankAccounts :bankAccountHeaders="bankAccountHeaders"/>
						</tab-panel>
						<tab-panel val="Peppol Identifiers">
							<ViewPeppolIdentifiers/>
						</tab-panel>
						<tab-panel val="Billing Info">
							<ViewBillingInfo/>
						</tab-panel>
						<tab-panel val="Reporting">
							<ViewReporting isFor="legalEntity"/>
						</tab-panel>
					</tab-panels>
				</div>
			</div>
		</div>
	</div>
	<EditGeneral/>
	<EditPeppolDocuments isFor="legalEntity"/>
	<EditPayment :paymentProvider="paymentProvider"/>
	<EditBankAccounts :bankAccountHeaders="bankAccountHeaders"/>
	<EditMail isFor="legalEntity" :langCodes="languages"/>
	<EditQr :qrOverlayData="qrOverlayData" :langCode="languages" isFor="legalEntity"/>
	<EditOverlay
		:langCodes="languages"
		:overlayOverlayData="overlayOverlayData"
		isFor="legalEntity"
	/>
	<EditIngestion :show="openEdit.ingestion" @close="openEdit.ingestion = false"></EditIngestion>
	<EditChannels :show="openEdit.channels" @close="openEdit.channels = false"/>
	<EditTac :langCodes="languages" isFor="legalEntity"/>
	<EditInserts :langCodes="languages" isFor="legalEntity"/>
	<EditPreIngestion isFor="legalEntity"/>
	<EditResendTemplate isFor="legalEntity"/>
	<EditReporting isFor="legalEntity" :languages="languages"/>
</template>
<script>
import {onMounted, ref, reactive, toRefs} from "vue"
import {useStore} from "vuex"
import {useRoute} from "vue-router"
import langCodes from "../langCodes"
import {Tabs, Tab, TabPanels, TabPanel} from "vue3-tabs"

import ViewGeneral from "../components/ViewLEDetails/ViewGeneral.vue"
import ViewIngestion from "@/components/ViewLEDetails/ViewIngestion.vue"
import ViewPeppolDocuments from "../components/ViewDetails/ViewPeppolDocuments.vue"
import ViewPayment from "../components/ViewLEDetails/ViewPayment.vue"
import ViewMailTemplate from "../components/templates/mail/ViewMailTemplate.vue"
import ViewQrTemplate from "../components/templates/qr/ViewQrTemplate.vue"
import ViewOverlayTemplate from "../components/templates/overlay/ViewOverlayTemplate.vue"
import ViewTac from "../components/templates/t&c/ViewTac.vue"
import ViewPreIngestion from "../components/templates/preIngestion/ViewPreIngestion.vue"
import ViewResendTemplate from "../components/templates/resend/ViewResendTemplate.vue"
import ViewBankAccounts from "../components/ViewLEDetails/ViewBankAccount.vue"
import ViewPeppolIdentifiers from "../components/ViewLEDetails/ViewPeppolIdentifiers.vue"
import ViewBillingInfo from "../components/ViewLEDetails/ViewBillingInfo.vue"
import ViewReporting from "../components/ViewDetails/ViewReporting.vue"
import EditGeneral from "../components/EditLegalEntity/EditGeneral.vue"
import EditPeppolDocuments from "../components/EditSupplierGroup/EditPeppolDocuments.vue"
import EditPayment from "../components/EditLegalEntity/EditPayment.vue"
import EditBankAccounts from "../components/EditLegalEntity/EditBankAccounts.vue"
import EditMail from "../components/templates/mail/EditMail.vue"
import EditQr from "../components/templates/qr/EditQR.vue"
import EditOverlay from "../components/templates/overlay/EditOverlay.vue"
import EditTac from "../components/templates/t&c/EditTac.vue"
import EditPreIngestion from "../components/templates/preIngestion/EditPreIngestion.vue"
import EditResendTemplate from "../components/templates/resend/EditResendTemplate.vue"
import EditReporting from "../components/EditSupplierGroup/EditReporting.vue"
import EditInserts from "../components/templates/inserts/EditInserts.vue"
import ViewInserts from "../components/templates/inserts/ViewInserts.vue"
import EditIngestion from "@/components/EditLegalEntity/EditIngestion.vue"
import ViewChannels from "@/components/ViewLEDetails/ViewChannels.vue";
import EditChannels from "@/components/EditLegalEntity/EditChannels.vue";

export default {
	components: {
		EditChannels,
		ViewChannels,
		EditIngestion,
		ViewIngestion,
		Tabs,
		Tab,
		TabPanels,
		TabPanel,
		ViewGeneral,
		ViewPeppolDocuments,
		ViewPayment,
		ViewMailTemplate,
		ViewQrTemplate,
		ViewOverlayTemplate,
		ViewTac,
		ViewInserts,
		ViewPreIngestion,
		ViewResendTemplate,
		ViewBankAccounts,
		ViewPeppolIdentifiers,
		ViewBillingInfo,
		ViewReporting,
		EditGeneral,
		EditPeppolDocuments,
		EditPayment,
		EditBankAccounts,
		EditMail,
		EditQr,
		EditOverlay,
		EditTac,
		EditInserts,
		EditPreIngestion,
		EditResendTemplate,
		EditReporting,
	},
	data() {
		return {
			openEdit : {
				ingestion: false,
				channels: false,
			}
		}
	},

	watch: {
		"$store.state.supplierGroups": {
			handler(data) {
				this.supplierGroup = data.find(
					(sg) => sg.supplierGroupId === this.$route.params.id
				)
			},
			deep: true
		},
		"$store.state.activeLE": {
			handler(data) {
				this.legalEntity = data
				this.getUniqueFiles()
			},
			deep: true
		},
		selectedTab() {
			this.updateSelectedTabName(this.selectedTab)
			if (this.selectedTab === "Templates") {
				this.updateSelectedTabName(this.selectedSubTabTemplates)
			}
		},
		selectedSubTabTemplates() {
			this.updateSelectedTabName(this.selectedSubTabTemplates)
		},
	},
	methods: {
		async qsIngestion() {
			await this.$store.dispatch("schedule/startQuickActionIngestion", this.$store.state.activeSG.supplierGroupId)
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: this.$store.state.activeSG.name,
					text: "Started QA Ingestion ",
				})
			} else {
				this.$store.commit("toggleAlert", {
					title: this.$store.state.activeSG.name,
					text: "ERROR",
				})
			}
			this.qsIngesionClicked = true
			setTimeout(() => {
				this.qsIngesionClicked = false
			}, 2000)
		},
		updateSelectedTabName(data) {
			this.selectedTabName = data
		},
		goBack() {
			this.$router.push("/SGOverview/" + this.$route.params.id)
		},
		view(string) {
			switch (string) {
				case "General":
					this.$store.state.displayEditLeGeneral = true
					break
				case "Ingestion":
					this.openEdit.ingestion = true
					break
				case "Channels":
					this.openEdit.channels = true
					break
				case "Peppol Documents":
					this.$store.state.displayEditPeppolDocuments = true
					break
				case "Payment":
					this.$store.state.displayEditLePayment = true
					break
				case "Bank Accounts":
					this.$store.state.displayEditLeBankAccounts = true
					break
				case "Billing Info":
					this.$store.state.displayEditBillingInfo = true
					break
				case "Reporting":
					this.$store.state.displayEditReporting = true
					break
				case "Mail":
					this.$store.state.displayEditMailTemplate = true
					break
				case "QR":
					this.$store.state.displayEditQRTemplate = true
					break
				case "Overlay":
					this.$store.state.displayEditOverlayTemplate = true
					break
				case "Terms and Conditions":
					this.$store.state.displayEditTacTemplate = true
					break
				case "Inserts":
					this.$store.state.displayEditInsertsTemplate = true
					break
				case "Pre-Ingestion":
					this.$store.state.displayEditPreIngestion = true
					break
				case "Resend":
					this.$store.state.displayEditResendTemplate = true
					break
				case "Peppol Identifiers" :
					this.$store.state.displayEditPeppol = true
					break
			}
		}
	},
	setup() {
		const route = useRoute()
		const store = useStore()
		store.dispatch("fetchSupplierGroups")
		const supplierGroup = ref(store.state.supplierGroups.find((sg) => sg.supplierGroupId === route.params.id))
		const legalEntity = ref(
			Object.values(supplierGroup.value.supplierLegalEntities).find((le) => le.entityNumber === route.params.idLe)
		)
		store.state.activeSG = supplierGroup.value
		store.state.activeLE = legalEntity.value
		const selectedTabName = ref("General")
		const qsIngesionClicked = ref(false)
		const languages = langCodes
		const tabs = ["General", "Ingestion", "Channels", "Payment", "Templates", "Bank Accounts", "Reporting", "Peppol Documents", "Peppol Identifiers", "Billing Info"]
		const tabsTemplate = ["Mail", "QR", "Overlay", "Terms and Conditions", "Pre-Ingestion", "Resend", "Inserts"]
		const state = reactive({
			selectedSubTabTemplates: tabsTemplate[0],
			selectedTab: tabs[0]
		})
		const qrOverlayData = [
			{id: 0, name: "FIRST_PAGE_ONLY"},
			{id: 1, name: "LAST_PAGE_ONLY"}
		]
		const overlayOverlayData = [
			{id: 0, name: "ALL_PAGES"},
			{id: 1, name: "FIRST_PAGE_ONLY"},
			{id: 2, name: "LAST_PAGE_ONLY"},
			{id: 3, name: "EVEN_PAGES_ONLY"},
			{id: 4, name: "ODD_PAGES_ONLY"}
		]
		const bankAccountHeaders = ['Index', 'Bankname', 'IBAN', 'BIC']
		const paymentProvider = ref("")
		const getPaymentProvider = () => {
			if ((supplierGroup.value).channelsConfig.payment) {
				return paymentProvider.value = (supplierGroup.value).channelsConfig.payment.provider
			}
			return paymentProvider.value = ''
		}
		const getUniqueFiles = () => {
			store.state.tacFilesLE = []
			store.state.bankAccounts = []
			store.state.receiverMailLE = {}
			let tacFiles =  legalEntity.value.templates?.terms ? legalEntity.value.templates.terms : []
			store.state.tacFilesLE = [...tacFiles]
			let bankAccounts = legalEntity.value.bankAccounts ? legalEntity.value.bankAccounts : []
			store.state.bankAccounts = [...bankAccounts]
			let receiverMails = legalEntity.value.legalEntityConfig?.reportConfig ?
				legalEntity.value.legalEntityConfig.reportConfig.receiverEmails : {}
			for (let item in receiverMails) {
				store.state.receiverMailLE[item] = receiverMails[item]
			}
		};
		onMounted(() => {
			getPaymentProvider()
		})
		return {
			selectedTabName,
			supplierGroup,
			tabs,
			languages,
			tabsTemplate,
			qrOverlayData,
			overlayOverlayData,
			legalEntity,
			qsIngesionClicked,
			paymentProvider,
			bankAccountHeaders,
			getUniqueFiles,
			...toRefs(state),
		}
	}
}
</script>
<style scoped>
.back {
	cursor: pointer;
}

.hide {
	opacity: 0;
}

.tab {
	cursor: pointer;
	padding: 0;
}

.active {
	background-color: #00adea;
	color: white;
	border: none;
}

.dialog-enter-active {
	transition: all 0.3s ease-out;
}

.dialog-leave-active {
	transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
	transform: scale(0.8);
	opacity: 0;
}
</style>
