<template>
	<div class="w-full">
		<div class="content">
			<!--Content-->
			<div class="intro-y grid grid-cols-12 gap-6 mt-5">
				<div class="col-span-12">
					<div class="intro-y box">
						<div class="p-5">
							<h2 class="font-medium text-base mr-auto">Select Export Option</h2>
							<ul 
								id="exportOption"
								class="filter-switch tab-buttons relative  h-10  p-1 space-x-1 bg-gray-200 rounded-md font-semibold text-blue-600 my-4"
							>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										:class="{ active: currentOption === 'ERROR_OVERVIEW' }"
										id="ERROR_OVERVIEW"
										@click="selectOption($event.target.id)"
										class="h-8 py-1 px-2 text-sm leading-6 text-gray-600  hover:text-gray-800 bg-white rounded shadow"
									>
										Errors
									</div>
									<div aria-hidden="true" class="filter-active"></div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'MAIL_ERRORS' }"
										id="MAIL_ERRORS"
										class="  h-8  py-1 px-2 text-sm leading-6  text-gray-600 hover:text-gray-800  bg-white rounded shadow"
									>
										Delivery Errors
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'CHANNEL_DETAIL' }"
										id="CHANNEL_DETAIL"
										class=" h-8  py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow"
									>
										Channel detail
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'CHANNEL_ARCHIVED_DETAIL' }"
										id="CHANNEL_ARCHIVED_DETAIL"
										class=" h-8  py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow"
									>
										Channel detail archived
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'DOCUMENTS_HELD' }"
										id="DOCUMENTS_HELD"
										class="h-8  py-1 px-2 text-sm leading-6  text-gray-600  hover:text-gray-800  bg-white rounded shadow"
									>
										Documents held
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'BILLING' }"
										id="BILLING"
										class="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow"
									>
										Billing
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'ARCHIVE' }"
										id="ARCHIVE"
										class="  h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow"
									>
										Archiving
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'ARCHIVE_DETAIL' }"
										id="ARCHIVE_DETAIL"
										class=" h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow"
									>
										Archiving detail
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'CHANNEL' }"
										id="CHANNEL"
										class="  h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white  rounded shadow"
									>
										Channel
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'DATA_STUDIO_CHANNEL_DETAIL' }"
										id="DATA_STUDIO_CHANNEL_DETAIL"
										class=" h-8 py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded  shadow "
									>
										Data studio channel detail
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'TRANSACTIONS' }"
										id="TRANSACTIONS"
										class=" h-8 py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded  shadow "
									>
										Transactions
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'MANDATES_OVERVIEW' }"
										id="MANDATES_OVERVIEW"
										class=" h-8 py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded  shadow "
									>
										Mandate overview
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'MANDATES_CHOICES' }"
										id="MANDATES_CHOICES"
										class=" h-8 py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded  shadow "
									>
										Mandate choices
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'CHANNEL_CHOICE' }"
										id="CHANNEL_CHOICE"
										class=" h-8 py-1  px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded  shadow "
									>
										Channel choice
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'POST_DROP' }"
										id="POST_DROP"
										class="  h-8  py-1 px-2 text-sm leading-6  text-gray-600  hover:text-gray-800  bg-white rounded shadow"
									>
										NymDrop
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'SG_SPECIFIC_ERRORS' }"
										id="SG_SPECIFIC_ERRORS"
										class="  h-8  py-1 px-2 text-sm leading-6  text-gray-600  hover:text-gray-800  bg-white rounded shadow"
									>
										General report
									</div>
								</li>
								<li class="filter-switch-item flex relative h-8 bg-gray-300x">
									<div
										@click="selectOption($event.target.id)"
										:class="{ active: currentOption === 'UBL_STATUS_REPORT' }"
										id="UBL_STATUS_REPORT"
										class="  h-8  py-1 px-2 text-sm leading-6  text-gray-600  hover:text-gray-800  bg-white rounded shadow"
									>
										UBL status
									</div>
								</li>
							</ul>
							<h2 class="font-medium text-base mr-auto">Select Supplier Group(s)</h2>
							<DropdownSelectAll
								:class="{ 'border border-primary-3': sgInvalid }"
								@update-value="updateSupplierGroups"
								:data="supplierGroups"
							/>
							<span v-if="sgInvalid" class="text-primary-3">At least 1 suppliergroup required</span>
							<div v-if="isVisibleLegalEntity">
								<h2 class="font-medium text-base mr-auto">Select Legal Entity</h2>
								<DropdownSelectAll
									v-if="renderComponent"
									@update-value="updateLegalEntity"
									:data="legalEntities.sort((a, b) => a.name.localeCompare(b.name))"
									:noSelectAll="true"
								/>
							</div>
							<div class="date-container">
								<div>
									<h2 class="font-medium text-base mr-auto">Select From date:</h2>
									<vue-date-picker
										v-model="selectedDate.from"
										ref="datePicker"
										format="dd-MM-yyyy"
										@internalModelChange="getDateOnLostFocus($event,'from')"
										@blur="setDateOnLostFocus('from')"
									>

									</vue-date-picker>
								</div>
								<div>
									<h2 class="font-medium text-base mr-auto">Select To date:</h2>
									<vue-date-picker
										@internalModelChange="getDateOnLostFocus($event,'to')"
										ref="datePicker"
										@blur="setDateOnLostFocus('to')"
										format="dd-MM-yyyy"
										v-model="selectedDate.to"
									></vue-date-picker>
								</div>
								
								<button
									class="btn bg-theme-1 text-white object-contain"
									@click="resetDates()"
								>
									Reset
								</button>
							</div>
							<button
								class="btn bg-theme-1 text-white mt-5 object-contain"
								@click="postReport()"
							>
								Export as csv
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DropdownSelectAll from "../components/DropdownSelectAll.vue"
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
export default {
	name: "ExportingPage",
	components: {
		VueDatePicker,
		DropdownSelectAll: DropdownSelectAll,
	},
	computed: {
		getCsvResponse() {
			return this.$store.state.csvResponse
		},
	},
	watch: {
		selectedSupplierGroup() {
			this.selectOption(this.currentOption);
			if (this.selectedSupplierGroup.length === 0) {
				this.legalEntities = []
			}
		},
		getCsvResponse(data) {
			if (data !== "") {
				this.downloadCsv(data);
			}
		},
	},
	data() {
		return {
			renderComponent: true,
			isVisibleLegalEntity: false,
			dateYesterday: "",
			date: "",

			selectedDate: {
				from: "",
				to: " ",
			},

			internalDate: {
				from: '',
				to: ''
			},
			supplierGroups: this.$store.state.supplierGroups,
			selectedSupplierGroup: [],
			legalEntities: [],
			selectedLegalEntity: [],
			currentOption: "ERROR_OVERVIEW",
			sgInvalid: false,
		}
	},
	async beforeMount() {
		const date = new Date()
		date.setDate(date.getDate() - 1)
		date.setHours(0, 0, 0, 0)
		const date2 = new Date()
		date2.setDate(date2.getDate() - 1)
		date2.setHours(23, 59, 59, 999)
		this.date = this.dateYesterday = [date, date2]
		this.selectedDate.from = date
		this.selectedDate.to = date2
		this.selectOption("ERROR_OVERVIEW")
	},
	methods: {
		getDateOnLostFocus(data, field) {
			this.internalDate[field] = data
		},
		setDateOnLostFocus(field) {
			this.selectedDate[field] = this.internalDate[field]
		},
		updateLegalEntity(data) {
			let foundArr = []
			data.forEach(item => {
				foundArr.push(this.legalEntities[item].entityNumber)
			})

			this.selectedLegalEntity = foundArr
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true
			})
		},
		updateSupplierGroups(data) {
			this.forceRerender()
			this.selectedLegalEntity = []
			let foundArr = []
			for (let idx of data) {
				let found = this.supplierGroups.at(idx)
				foundArr.push(found)
			}
			this.selectedSupplierGroup = foundArr
			this.validate()
		},
		async postReport() {
			if (this.validate()) {
				return
			}
			this.selectedDate.from.setHours(0, 0, 0, 0)
			this.selectedDate.to.setHours(23, 59, 59, 999)
			let data = {
				reportType: this.currentOption,
				SG: {},
				from: `${this.selectedDate.from.toISOString()}`,
				to: `${this.selectedDate.to.toISOString()}`,
			}
			for (let item of this.selectedSupplierGroup) {
				let arrLE = []
				for (const [_, value] of Object.entries(item.supplierLegalEntities)) {
					arrLE.push(value.entityNumber)
				}
				if (this.selectedSupplierGroup.length === 1 && this.selectedLegalEntity.length !== 0) {
					data.SG[item.supplierGroupId] = this.selectedLegalEntity
				} else {
					data.SG[item.supplierGroupId] = arrLE
				}
			}

			await this.$store.dispatch("exporting/postReport", data)
		},
		downloadCsv(data) {
			let hasErrors = this.validate()
			if (!hasErrors) {
				let hiddenElement = document.createElement("a")
				let url = encodeURI(data)
				hiddenElement.href = "data:text/csv;charset=utf-8," + url.replace(/#/g, '%23')
				hiddenElement.target = "_blank"
				if (this.selectedSupplierGroup.length === 1) {
					if (this.currentOption === "MAIL_ERRORS") {
						hiddenElement.download = `${this.selectedSupplierGroup[0].name}_DELIVERY_ERORS_export.csv`
					} else {
						if (this.currentOption === "SG_SPECIFIC_ERRORS") {
							hiddenElement.download = `${this.selectedSupplierGroup[0].name}_GENERAL_REPORT_export.csv`
						} else {
							hiddenElement.download = `${this.selectedSupplierGroup[0].name}_${this.currentOption}_export.csv`
						}
					}
				} else {
					if (this.currentOption === "MAIL_ERRORS") {
						hiddenElement.download = `DELIVERY_ERORS_export.csv`
					} else {
						if (this.currentOption === "SG_SPECIFIC_ERRORS") {
							hiddenElement.download = `GENERAL_REPORT_export.csv`
						} else {
							hiddenElement.download = `${this.currentOption}_export.csv`
						}
					}
				}
				hiddenElement.click();
				this.$store.state.csvResponse = ""
			}
		},
		validate() {
			this.sgInvalid = false
			let hasErrors = false
			if (this.selectedSupplierGroup.length === 0) {
				this.sgInvalid = true
				hasErrors = true
			}
			return hasErrors
		},
		selectOption(id) {
			this.currentOption = id
			switch (this.currentOption) {
				case "SG_SPECIFIC_ERRORS":
				case "MAIL_ERRORS":
				case "ARCHIVE_DETAIL":
				case "CHANNEL":
				case "CHANNEL_DETAIL":
				case "CHANNEL_ARCHIVED_DETAIL":
				case "DATA_STUDIO_CHANNEL_DETAIL":
				case "TRANSACTIONS":
				case "MANDATES_OVERVIEW":
				case "MANDATES_CHOICES":
				case "CHANNEL_CHOICE":
				case "DOCUMENTS_HELD":
					if (this.selectedSupplierGroup.length > 1) this.isVisibleLegalEntity = false
					else {
						if (this.selectedSupplierGroup.length === 1) {
							this.legalEntities = []
							let leArray = []

							for (let item in this.selectedSupplierGroup[0].supplierLegalEntities) {
								this.legalEntities.push(this.selectedSupplierGroup[0].supplierLegalEntities[item])

								let leName = this.selectedSupplierGroup[0].supplierLegalEntities[item].internalName
									? this.selectedSupplierGroup[0].supplierLegalEntities[item].internalName
									: this.selectedSupplierGroup[0].supplierLegalEntities[item].name

								leArray.push({
									name: leName,
									entityNumber: this.selectedSupplierGroup[0].supplierLegalEntities[item].entityNumber
								})
							}

							this.legalEntities = leArray
						}
						this.isVisibleLegalEntity = true
					}
					break
				default:
					this.isVisibleLegalEntity = false
					break
			}
		},
		resetDates() {
			this.selectedDate.from = this.dateYesterday[0]
			this.selectedDate.to = this.dateYesterday[1]
		}
	}
}
</script>
<style scoped>
.filter-switch div {
	cursor: pointer;
}

.active {
	color: white;
	background-color: #00adea;
}

.date-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 250px 250px 60px;
	grid-auto-flow: row;
}

.date-container button {
	align-self: end;
}

.tab-buttons {
	height: 78px;
	display: flex;
	flex-wrap: wrap;
	grid-template-rows: 50% 50%;
	grid-template-columns: repeat(7, auto);
}
</style>