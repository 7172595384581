<template>
    <div class="w-full ">
        <!-- BEGIN: Content -->
        <div class="content">
            <div class="button-container-outer">
                <div class="button-container running-jobs">
                    <div v-for="type in pollingType" :key="type.value">
                        <button
                              :class = '{"active-type" : type.active}'
                              @click="setActivePollingType(type.value)"
                              class="btn ">
                            {{type.name}}
                        </button>
                    </div>
                </div>
            </div>


            <ScheduleTable
                  :requestUrl ="requestUrl"
                  :token = "token"
                  :activePollingType = activePollingType
            >

            </ScheduleTable>

        </div>

    </div>

</template>

<script>
import ScheduleTable from "../components/Schedule/ScheduleTable";
export default {
    name: "SchedulePage",
    components: {ScheduleTable},
    data(){
        return{
            requestUrl: this.$store.state.baseUrl + this.$store.state.API.paymentJobs,
            token: this.$store.state.token,
            activePollingType : 'invoice',

            pollingType:[
                {
                    active: true,
                    name : "Invoice polling",
                    value : 'invoice'
                },
                {
                    active: false,
                    name : "Transaction polling",
                    value : 'transaction'
                },
                {
                    active: false,
                    name : "Mandate polling",
                    value : 'mandate'
                },
            ]
        }
    },
    methods:{
        setActivePollingType(selectedType){
            this.pollingType.forEach(type => {

                if (selectedType !== type.value){
                    type.active = false;
                } else {
                    type.active = true;
                }
            })


        }
    }
}
</script>

<style scoped>
.active-type{
    background-color: #00adea;
    color: white;
}

.button-container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    grid-column-gap: 10px;
}
.button-container-outer{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    justify-items: end;
    margin-top: 60px;
}
</style>
