<template>
	<div class="button-container">
		<button class="btn nymus-orange color-white"
				@click="onExportCsv">
			Download
		</button>
	</div>
	<div
		id="tabulator"
		ref="tableRef"
		class="mt-5 table-report table-report--tabulator"
	></div>

</template>

<script>
import {onMounted, reactive, ref, watch} from "vue"
import {useStore} from "vuex"
import {TabulatorFull as Tabulator} from 'tabulator-tables'

export default {
	name: "SmpParticipantsTable",
	props: {
		tableData: Array,
	},
	emits: ['enableButton'],

	setup(props, {emit}) {
		const store = useStore()
		const tableRef = ref()
		const tabulator = ref()
		const filter = reactive({})

		watch(() => props.tableData, (newVal) => {
			initTabulator()
		})
		watch(
			() => store.state.smpParticipantData,
			() => {
				initTabulator()
			}
		)

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {
				data: store.state.smpParticipants,
				printAsHtml: true,
				printStyled: true,
				pagination: "local",
				paginationSize: 10,
				layout: "fitColumns",
				reactiveData: true,
				responsiveLayout: "collapse",
				placeholder: "No matching records found",
				downloadConfig:{
					columnHeaders:false
				},
				columns: [
					{
						title: "IDENTIFIER",
						field: "identifier",
						hozAlign: "center",
						width: '200',
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                                		${cell.getData().identifier}
                    				</div>`
						}
					},
					{
						title: "ACCESS POINT URL",
						field: "accessPointUrl",
						hozAlign: "center",

						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                                		${cell.getData().accessPointUrl}
                    				</div>`
						}
					},
					{
						title: "DOCUMENT TYPE",
						field: "documentType",
						hozAlign: "center",
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                                		${cell.getData().documentType}
                    				</div>`
						}
					},
					{
						title: "SERVICE DESCRIPTION",
						field: "serviceDescription",
						hozAlign: "center",

						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                                		${cell.getData().serviceDescription}
                    				</div>`
						}
					}
				]
			})
		}

		const onExportCsv = () => {
			tabulator.value.download('csv', 'Peppol_Info.csv', {delimiter: ";"})
		}

		onMounted(async () => {
			await initTabulator();
			emit('enableButton')
		})

		return {
			tableRef,
			filter,
			onExportCsv
		}
	}
}
</script>

<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: end;
}
</style>