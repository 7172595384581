<template>
	<pop-up-window
		:show="showAddXsdFile"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add XSD</template>
		<template #content>
			<div class="flex flex-row">
				<div class="flex flex-col mb-1 w-1/2">
					<div class="mb-3 mr-3">
						<label for="xsdType" class="font-bold form-label">Type:*</label>
						<Dropdown
							id="xsdType"
							@update-value="updateSelectedXsdType"
							:selected="selectedType"
							:data="xsdData"
						/>
					</div>
					<FileUpload
						@update-value="changeXsdValue"
						accept=".xsd"
						id="addXsdLocation"
						title="Choose File:*"
						:invalid="fileInvalid"
						:data="selectedFile"
					/>
					<span v-if="fileInvalid" class="text-primary-3"
					>Select a XSD file</span
					>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="add">Add</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import FileUpload from "../FileUpload.vue";
import Dropdown from "../Dropdown.vue";

export default {
	name: "AddXsdFiles",
	components: {
		PopUpWindow,
		FileUpload,
		Dropdown,
	},
	props: {
		showAddXsdFile: Boolean,
		supplierGroup: Object,
		xsdData: Array,
	},
	data() {
		return {
			selectedType: "BILLING",
			selectedFile: "",
			file: "",
			fileInvalid: false,
		};
	},
	emits: ["add-xsd", "cancel"],
	methods: {
		updateSelectedXsdType(data) {
			this.selectedType = this.xsdData[data].name;
		},
		changeXsdValue(event) {
			this.selectedFile = event.value
			this.file = event.files[0]
			this.validate()
		},
		close() {
			this.selectedFile = "";
			this.file = "";
			this.fileInvalid = false;
			this.$emit('cancel')
		},
		add() {
			let hasErrors = this.validate();
			if (!hasErrors) {
				let data = {
					xsdType: this.selectedType,
					xsdFilename: this.file,
					xsdLocation: this.selectedFile,
				};
				this.$emit("add-xsd", data);
				this.close();
			}
		},
		validate() {

			this.fileInvalid = false;
			let hasErrors = false;
			if (!this.file) {
				hasErrors = true;
				this.fileInvalid = true;
			}
			return hasErrors;
		},
	},
};
</script>
