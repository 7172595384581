<template>
    <div>
        <!-- BEGIN: HTML Table Data -->
        <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <p
                      v-if="withLE"
                      class="cursor-pointer ml-2 mr-6 font-bold text-4xl"
                      @click="goBack()"
                >
                    &#171;
                </p>
                <form
                      @submit.prevent="onFilter"
                      id="tabulator-html-filter-form"
                      class="xl:flex sm:mr-auto"
                >
                    <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                        <input
                              id="tabulator-html-filter-value"
                              v-model="filter.value"
                              type="text"
                              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                              placeholder="Search..."
                              @keyup="onFilter"
                        />
                    </div>
                    <div class="mt-2 xl:mt-0">
                        <button
                              id="tabulator-html-filter-reset"
                              type="button"
                              class="btn w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                              @click="onResetFilter"
                        >
                            Reset
                        </button>
                    </div>
                </form>
                <div class="flex mt-5 sm:mt-0"></div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator"
                ></div>
            </div>
        </div>
        <!-- END: HTML Table Data -->
    </div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted, watch} from "vue";
import feather from "feather-icons";
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import cash from "cash-dom";
import {useRouter, useRoute} from "vue-router";
import {useStore} from "vuex";
import routerData from "../routerData";

export default defineComponent({
    props: {
        token: String,
        requestUrl: String,
        withLE: Boolean,
    },
    beforeMount(){
        this.$store.dispatch("updateToken")
    },

    methods: {
        goBack() {
            this.$router.back();
        },
    },
    setup(props) {
        const requestUrl = ref(props.requestUrl);
        const token = ref(props.token);
        const withLE = ref(props.withLE);
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        watch(
              () => props.token,
              (newVal) => {
                  token.value = newVal;
              }
        );
        store.state.filterSG = "";
        const tableRef = ref();
        const tabulator = ref();
        const filter = reactive({
            field: "name",
            type: "like",
            value: store.state.filterSG,
        });
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: requestUrl.value,
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        Authorization: "bearer " + token.value,

                        "Content-type": "application/json; charset=utf-8",
                    },
                },
                ajaxResponse: async function (url, params, response) {
                    let data = [];
                    const res = response.data.supplierGroups;

                    if (withLE.value) {
                        const SGres = res.find(
                              (el) => el.supplierGroupId === route.params.id
                        );

                        let qr = false;
                        if ((SGres.templates && SGres.templates.qr) || (SGres.templates && SGres.templates.qrTemplates)){

                            qr = true;
                        }
                        let mail = false;
                        if ((SGres.templates && SGres.templates.mail) || (SGres.templates && SGres.templates.mails)){
                            mail = true;
                        }
                        let overlay = false;
                        if ((SGres.templates && SGres.templates.overlay) || (SGres.templates && SGres.templates.overlayTemplates)){
                            overlay = true;
                        }

                        data.push({
                            supplierGroupId: SGres.supplierGroupId,
                            name: SGres.name,
                            apiConfig: SGres.apiConfig ? true : false,
                            signing: SGres.channelsConfig.signing ? true : false,
                            payment: SGres.channelsConfig.payment ? true : false,
                            linkEmail: SGres.channelsConfig.linkEmail ? true : false,
                            attachEmail: SGres.channelsConfig.attachEmail ? true : false,
                            edi: SGres.channelsConfig.edi ? true : false,
                            print: SGres.channelsConfig.print ? true : false,
                           // mail: SGres.templates ? SGres.templates.mail ? true   : false : false,
                            mail : mail,
                           // qr: SGres.templates ? (SGres.templates.qr ? true : false) : false,
                            qr: qr,
                         //   overlay: SGres.templates ? SGres.templates.overlay ? true : false : false,
                            overlay: overlay,
                            terms: false,
                        });



                        if (
                              SGres.templates ? (SGres.templates.terms ? true : false) : false
                        ) {
                            const found = data.find(
                                  (element) => element.supplierGroupId == SGres.supplierGroupId
                            );
                            for (let itm of SGres.templates.terms) {
                                found.terms = itm.mergeToBillingDocument;
                            }
                        }
                        for (let le in SGres.supplierLegalEntities) {

                            let qr = false;

                            if((SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.qr)
                                  || (SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.qrTemplates) ){

                                qr = true
                            }
                            let mail = false;
                            if((SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.mail)
                                  || (SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.mails) ){

                                mail = true
                            }

                            let overlay = false;
                            if((SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.overlay)
                                  || (SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.overlayTemplate)
                                  || (SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.overlayTemplates)){
                                overlay = true
                            }

                            data.push({
                                supplierGroupId: SGres.supplierGroupId,
                                name: SGres.supplierLegalEntities[le].name,
                                entityNumber: SGres.supplierLegalEntities[le].entityNumber,
                                apiConfig: null,
                                payment: SGres.supplierLegalEntities[le].legalEntityConfig
                                      ? SGres.supplierLegalEntities[le].legalEntityConfig.payment
                                            ? true
                                            : false
                                      : false,
                                linkEmail: null,
                                attachEmail: null,
                                edi: null,
                                print: null,
                             //   mail: SGres.supplierLegalEntities[le].templates ? SGres.supplierLegalEntities[le].templates.mail ? true: false : false,
                                mail : mail,
                            //    qr: SGres.supplierLegalEntities[le].templates  ? SGres.supplierLegalEntities[le].templates.qr ? true : false : false,
                                qr: qr,
                            //    overlay: SGres.supplierLegalEntities[le].templates ? SGres.supplierLegalEntities[le].templates.overlay  ? true : false : false,
                                overlay : overlay,
                                terms: false,
                                bank: SGres.supplierLegalEntities[le].bankAccounts
                                      ? SGres.supplierLegalEntities[le].bankAccounts.length >= 1
                                      : false,
                            });


                            if ((SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.qr)
                                  || (SGres.supplierLegalEntities[le].templates && SGres.supplierLegalEntities[le].templates.qrTemplates) ){

                                data.qr = true
                            }


                            if (
                                  SGres.supplierLegalEntities[le].templates
                                        ? SGres.supplierLegalEntities[le].templates.terms
                                              ? true
                                              : false
                                        : false
                            ) {
                                const founded = data.find(
                                      (element) => element.name == SGres.supplierLegalEntities[le].name
                                );
                                for (let itm of SGres.supplierLegalEntities[le].templates
                                      .terms) {
                                    founded.terms = itm.mergeToBillingDocument;
                                }
                            }
                        }
                    } else {

                        for (let item of res) {

                            let qr = false;
                            if ((item.templates && item.templates.qr) || (item.templates && item.templates.qrTemplates)){
                               qr = true
                            }
                            let mail = false;
                            if ((item.templates && item.templates.mail) || (item.templates && item.templates.mails)){
                                mail = true
                            }
                            let overlay = false;

                            if ((item.templates && item.templates.overlay ) || (item.templates && item.templates.overlayTemplates)){
                                overlay  = true
                            }


                            data.push({
                                supplierGroupId: item.supplierGroupId,
                                name: item.name,
                                apiConfig: item.apiConfig ? true : false,
                                signing : item.channelsConfig.signing ? true : false,
                                payment: item.channelsConfig.payment ? true : false,
                                linkEmail: item.channelsConfig.linkEmail ? true : false,
                                attachEmail: item.channelsConfig.attachEmail ? true : false,
                                edi: item.channelsConfig.edi ? true : false,
                                print: item.channelsConfig.print ? true : false,
                               // mail: item.templates ? item.templates.mail ? true : false : false,
                                mail : mail,
                              /*  qr: item.templates
                                      ? (item.templates.qr ? true : false)
                                      : false,

                               */
                                qr: qr,
                             //   overlay: item.templates ? item.templates.overlay ? true : false  : false,
                                overlay : overlay,
                                terms: false,
                            });

                            if (
                                  item.templates ? (item.templates.terms ? true : false) : false
                            ) {
                                const found = data.find(
                                      (element) => element.supplierGroupId == item.supplierGroupId
                                );
                                for (let itm of item.templates.terms) {
                                    found.terms = itm.mergeToBillingDocument;
                                }
                            }
                            if(item.templates && item.templates.qr){
                                data.qr = true;
                            }

                        }


                    }


          let formatedData = data;
          return formatedData; //return the tableData property of a response json object
        },

                ajaxContentType: "json",
                ajaxFiltering: false,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: "local",
                paginationSize: 10,
                layout: "fitColumns",
                reactiveData: true,
                responsiveLayout: "collapse",
                placeholder: "No matching records found",
                columns: [
                    {
                        title: "NAME",
                        field: "name",
                        width: "250",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div>${cell.getData().name}</div>
                    </div>`;
                        },
                    },
                    {
                        title: "API",
                        field: "apiConfig",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().apiConfig
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "SIGNING",
                        field: "apiConfig",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().signing
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "PAY",
                        field: "payment",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().payment
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "LINK",
                        field: "linkEmail",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().linkEmail
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "ATTACH",
                        field: "attachEmail",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().attachEmail
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "EDI",
                        field: "edi",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().edi
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "PRINT",
                        field: "print",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().print
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "MAIL",
                        field: "mail",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().mail
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "QR",
                        field: "qr",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().qr
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "OVERLAY",
                        field: "overlay",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().overlay
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "TERMS",
                        field: "terms",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                  cell.getData().terms
                            }"></div>
                    </div>`;
                        },
                    },
                    {
                        title: "",
                        field: "routing",
                        width: "60",
                        hozAlign: "center",
                        formatter(cell) {
                            const a =
                                  cash(`<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                                <p class="font-bold text-3xl">&#187</p>
                            </div>`);
                            cash(a).on("click", async function () {
                                if (!withLE.value) {
                                    router.push(
                                          routerData.Summary.link + "/" + cell.getData().supplierGroupId
                                    );
                                } else {
                                    if (cell.getData().print === null) {
                                        router.push(routerData.SGOverview.link +
                                              "/" + cell.getData().supplierGroupId +
                                              "/" + cell.getData().entityNumber
                                        );
                                    } else {
                                        router.push(
                                              routerData.SGOverview.link + "/" + cell.getData().supplierGroupId);
                                    }
                                }
                            });
                            return a[0];
                        },
                    },
                ],
            });
            onFilter();
        };

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener("resize", () => {
                tabulator.value.redraw();
                feather.replace({
                    "stroke-width": 1.5,
                });
            });
        };
        // Filter function
        const onFilter = () => {
            store.state.filterSG = filter.value;
            tabulator.value.setFilter([
                [
                    {field: "name", type: filter.type, value: filter.value},
                    {field: "supplierGroupId", type: filter.type, value: filter.value},
                ],
            ]);
            /* tabulator.value.setFilter("name", filter.type, filter.value) */
        };

        // On reset filter
        const onResetFilter = () => {
            filter.field = "name";
            filter.type = "like";
            filter.value = "";
            onFilter();
        };

        onMounted(() => {
            initTabulator();
            reInitOnResizeWindow();
            if (withLE.value) {
                tabulator.value.addColumn(
                      {
                          title: "BANK",
                          field: "bank",
                          hozAlign: "center",
                          formatter(cell) {
                              return `<div  class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1 justify-items-center">
                          <div class="text-2xl summary-${
                                    cell.getData().bank
                              }"></div>
                    </div>`;
                          },
                      },
                      false, "terms"
                );
            }
        });

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
        };
    },
});
</script>

<style>
.tabulator-arrow {
    display: none;
}

.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}

.summary-true::before {
    color: green;
    content: "✓";
}

.summary-false::before {
    color: red;
    content: "⨯";
    font-size: larger;
}
</style>
